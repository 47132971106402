@import url("https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;

  @screen md {
    font-size: 13px;
  }

  @screen lg {
    font-size: 16px;
  }
}

body {
  font-family: "Jost", sans-serif;
  background-color: #ffffff;
}

svg {
  display: block;
  width: 100%;
  height: 100%;
}

.borderRadiusSliderinput::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 10px;
  background: #000000;
  cursor: pointer;
  border-radius: 100%;
  transform: translateY(-8px);
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d9d9d9;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #191919;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #191919;
}
